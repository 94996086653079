@import '../../../index.scss';

.mp-table-section {
  .mp-table {
    table {
      max-width: 100%;
      font-family: $proxima-nova;
      .sort-icon {
        margin-left: 3px;
        color: #008eb5;
      }
      th {
        @media (max-width: 767px) {
          font-size: 12px !important;
        }
        max-width: 16.6%;
        padding: 5px;
        text-align: center;
        z-index: 0;
        span {
          z-index: 0;
        }
      }
    }
    .prospectBtn {
      background: #aae7f8;
      color: #30748c;
      padding: 3px;
    }
  }
}