.confirmation-dialog {
  .title {
    background: #008eb5;
    padding: 10px 20px;
    h2 {
      color: #fff;
      font-size: 18px;
    }
  }
  .content {
    padding: 10px;
    width: 100%;
  }
  .button {
    font-size: 14px;
    color: #53c1e8;
    &:hover {
      background: #53c1e8;
      color: #fff;
    }
  }
}