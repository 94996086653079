@import '../../../index.scss';

.mp-filters {
  font-family: $proxima-nova;
  .filter-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .filters {
      display: flex;
      flex-direction: column;
      width: 380px;
      .status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        label {
          color: #008eb5;
        }
        .select {
          width: 75%;
          background: #fff;
          border-radius: 4px;
          font-size: 14px;
          border: 1px solid #008eb5;
          #select-status {
            padding-left: 8px;
            color: #bbb;
          }
        }
      }
      .amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #bbb;
        margin-top: 5px;
        label {
          width: 20%;
        }
        .range {
          width: 75%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          label {
            text-align: center;
          }
          .input {
            border-radius: 4px;
            border: 1px solid #bbb;
            input {
              padding: 6px;
              font-size: 12px;
            }
          }
        }
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        button {
          font-size: 14px;
          border-radius: 4px;
          width: 40%;
        }
        .green {
          background: #d3e8e3;
          color: #3e7a64;
        }
        .blue {
          background: #aae7f8;
          color: #30748c;
        }
      }
    }
  }
}