.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
  }.sidebar-blue-btn{
        background-color: white;
        color: #0085ad;
  }
  .heading {
      background-color: #ccc;
      color: #fff;
      padding: 20px 0;
      text-align: center;
  }

  .heading h1 {
      font-size: 40px;
      text-transform: uppercase;
  }

  .heading h6 {
      font-size: 24px;
      margin-bottom: 0;
      text-transform: uppercase;
  }

  .box{
      align-items: center;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      cursor: pointer;
      margin-top: 70px;
      height: auto;
      width: 100%;
      box-shadow: 0 .125rem .25rem 0.125rem rgba(0,0,0,.075);
      font-size: 16px;
      .padd{
        padding: 20px 50px 0;
      }
  }
  .mb-70{
    margin-bottom: 70px;
  }
  .box.box-user {
      flex-direction: row;
      padding: 0 15px;
      text-align: left;
  }

  .box p.join {
      background-color: #fb434f;
      border-radius: 0 0 5px 5px;
      color: #fff;
      width: 100%;
      margin: 0;
      padding: 20px 30px;
      font-size: 18px;
      min-height: 115px;
  }

  .box p.create {
    background-color: #5ed0ed;
    border-radius: 0 0 5px 5px;
    color: #fff;
    margin: 0;
    width: 100%;
    margin-top: 10px;
    padding: 20px 30px;
    font-size: 18px;
    min-height: 115px;
  }

  .view-detail {
    background-color: #5ed0ed;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    text-align: center;
  }

  .or {
    align-items: center;
    background-color: #5ed0ed;
    border-radius: 50%;
    color: #fff;
    display: flex;
    height: 70px;
    justify-content: center;
    width: 70px;
    font-size: 34px;
    font-weight: 700;
  }
  .justify-content-center{
    justify-content: center;
  }
  .row-center{
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .both-img{
    object-fit: cover;
    height: auto;
    border-radius:10px;
  }
  .row .col-sm-2, .row .col-sm-5 {
      display: flex;
  }
  .box-mobile{
    margin-bottom: 50px;
    height: auto;
  }
  .or-mobile{
    display: none;
  }
  @media (max-width: 991px) {
    .box p.create{

    }
  }
  @media (max-width: 767px) {
    .row-center{
      display: initial;
    }
    .or{
      margin-top: 50px;
    }
    .box{
      margin-top: 50px;
      height: auto;
    }
    .box p.create{
      height: auto;
    }
    .heading{
      padding: 20px 10px;
    }
    .heading h1 {
      font-size: 30px;
    }
    .heading h6 {
      font-size: 20px;
    }
    .or-mobile{
      display: block;
    }
  }