@import '../../index.scss';

#contact-wrapper {
    background-image: url('../../assets/images/contact_bg.png');
    padding: 50px 0;
    .sub-header {
        font-style: normal;
        font-weight: 400;
        text-align: left;
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 40px;
        &:after {
            width: 25px;
            height: 2px;
            margin-top: 10px;
            background: #1b1b1b;
            display: block;
            content: "";
        }
    }
    p {
        color: #656363;
        font-family: 'Roboto-light', arial;
        font-size: 18px;
        font-weight: 100;
        text-align: left;
        line-height: 30px;
    }
    h6 {
        color: #5b5b5b;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        margin: 0px;
    }
    label{
        margin-bottom: 5px;
    }
    .input_box {
        width: 100%;
        height: auto;
        background: white;
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-sizing: border-box;
        font-size: 17px;
        font-family: 'Roboto';
        font-weight: normal;
        padding: 7px 10px !important;
        margin-bottom: 20px;
        line-height: 24px;
        text-indent: 0;
    }
    .input_box:focus {
        background: #f7f7f7;
        animation-name: input_change;
        animation-duration: 0.3s;
    }
    @keyframes input_change {
        from {
            background: white;
        }
        to {
            background: #f7f7f7;
        }
    }
    textarea {
        resize: none;
    }
    .btn-send {
        background-color: #1d1d1d;
        border-radius: 5px;
        color: white;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 15px;
        height: auto;
        outline: none !important;
        padding: 7px 50px;
        margin-bottom: 10px;
        text-decoration: none;
    }
    .btn.btn-round{
        margin-top: 25px;
        border-radius: 20px;
        background: $light_green;
        border: none;
        padding: 10px 20px;
        font-weight: 900;
        font-size: 13px;
        text-transform: uppercase;
        img{
          max-width: 20px;
        //   margin-top: -5px;
        }
      }
    .open_menu {
        -webkit-transition: 250ms all;
        transition: 250ms all;
      }
}