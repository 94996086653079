html {
  font-size: 14px;
}

#new-campaign-form {
  padding: 0 60px 20px 60px;
  @media (max-width: 767px) {
    padding: 0 5px;
  }
  .paper {
    padding: 10px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 767px) {
      max-height: 60vh;
    }
  }
  .title {
    width: 100%;
    padding: 5px 0;
    margin-top: 20px;
    font-size: 24px;
    color: #008eb5;
    border-bottom: 3px solid #008eb5;
  }
  label {
    font-size: 14px;
    font-weight: 400;
  }
  p {
    font-size: 12px;
    color: #cb3837;
  }
  .item {
    padding: 5px;
    .switch-label {
      width: 150px;
    }
    .camp-name {
      position: relative;
      label {
        position: absolute;
        padding: 6px;
      }
      .input {
        padding-left: 125px;
      }
    }
    &.main-image {
      label {
        margin-bottom: 10px;
      }
      .image-container {
        width: 150px;
        height: 150px;
        border: 1px solid #ddd;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
        }
      }
      button {
        width: 150px;
        margin-top: 10px;
        padding: 5px 0;
        font-size: 16px;
        background: #e26060;
        color: #fff;
        border: none;
      }
    }
    &.column {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      .input, .select, textarea {
        border-radius: 4px;
        border: 1px solid #ddd;
      }
      input, .select {
        height: 32px;
      }
      input, textarea {
        padding: 4px;
        font-size: 14px;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      .react-datepicker__input-container {
        width: 100%;
      }
      .select {
        font-size: 14px;
        select {
          padding: 4px;
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
  }
  .toolbar { 
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .editor {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    .public-DraftEditor-content {
      height: 100px;
    }
  }
  .camp-addons {
    display: flex;
    div {
      margin-right: 10px;
      padding: 5px 15px;
      border: none;
      border-radius: 4px;
      background: #d3e8e3;
      color: #3b7862;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .submit-btn {
    width: 100%;
    button {
      float: right;
      font-size: 18px;
      background: #aae7f8;
      color: #008eb5;
      border-radius: 4px;
      border: none;
      padding: 5px 35px;
    }
  }
}

.item-flex{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #eee;
}
.item-flex:hover {
  background-color: #f7f7f7;
}
.p-10{
  padding: 10px;
}
.additional-info-dialog-position{
  position: relative;
}
.action-fixed{
  position: fixed;
  bottom: 60px;
  left: 0;
  text-align: right;
  margin: auto!important;
  right: 22%;
  max-width: 960px;
}
@media (min-width: 991px){
  #additional-info-dialog .container {
    height: 500px;
  }
}

@media (max-width: 1024px) {
  right: 16%;
}
@media (max-width: 959px) {
  .action-fixed{
    bottom: 20px;
    right: 20px;
  }
}
