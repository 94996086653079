//------Common CSS----------
.middle-font {
    color:rgb(90, 90, 90);
    font-family: 'Roboto-Light', sans-serif;
    font-size: 100%;
    font-weight: 100;
}

.title-font {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 38px;
    text-align: left;
    text-transform: uppercase;
}

.top-banner {
    padding-top: 75px;
    h2 {
        font-size: 45px;
        color: #5a8fa4;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        font-style: normal;
        line-height: 55px;
        padding-bottom: 20px;
        margin: 0;
    }
}

#benefits-wrapper {
    margin: 0 auto;
    max-width: 1400px;
    clear: both;
    a {
        display: inline-block;
        width: 11%;
        text-align: center;
        text-decoration: none;
        float: left;
        &:hover {
            cursor: pointer;
        }
        img {
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
        }
        p {
            font-size: 10px;
            font-family: Arial, Helvetica, sans-serif;
            text-transform: uppercase;
            line-height: 16px;
            color: #191919;
            font-stretch: 100%;
            vertical-align: baseline;
        }
    }
}
.detail-list {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
    li {
        background-repeat: no-repeat;
        background-size: 12px;
        background-position-x: left;
        background-position-y: 6px;
        padding-left: 20px;
    }
}
.detail-list.blue li {
    background-image: url('../../assets/images/whychooseus/blue-icon.png');
}
.detail-list.red li {
    background-image: url('../../assets/images/whychooseus/red-icon.png');
}
.detail-list.yellow li {
    background-image: url('../../assets/images/whychooseus/yellow-icon.png');
}
.detail-list.brown li {
    background-image: url('../../assets/images/whychooseus/brown-icon.png');
}
.detail-list.darkpink li {
    background-image: url('../../assets/images/whychooseus/darkpink-icon.png');
}
.detail-list.green li {
    background-image: url('../../assets/images/whychooseus/green-icon.png');
}
.detail-list.lightblue li {
    background-image: url('../../assets/images/whychooseus/light-blue.png');
}

.benefits-detail-wrapper {
    padding-top: 100px;
    padding-bottom: 80px;
    position: relative;
    &:after {
        bottom: -40px;
        left: calc(50% - 50px);
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        z-index: 100;;
    }
}

#state-of-the-art {
    background-color: #d9f4fc;
    .title-font {
        color: #1d92b6;
    }
    &:after {
        border-top: 40px solid #d9f4fc;
    }
}

#unique-marketing {
    background-color: #fff9d5;
    .title-font {
        color: #bfa71f;
    }
    &:after {
        border-top: 40px solid #fff9d5;
    }
}

#customized-campaign {
    background-color: #f2f2f2;
    .title-font {
        color: #898989;
    }
    &:after {
        border-top: 40px solid #f2f2f2;
    }
}

#media-production {
    background-color: #f1fef0;
    .title-font {
        color: #51b748;
    }
    &:after {
        border-top: 40px solid #f1fef0;
    }
}

#verbiage-writeup {
    background-color: #fcf2df;
    .title-font {
        color: #b78f48;
    }
    &:after {
        border-top: 40px solid #fcf2df;
    }
}

#design-team {
    background-color: #fef3f0;
    .title-font {
        color: #cc6c4e;
    }
    &:after {
        border-top: 40px solid #fef3f0;
    }
}

#power-donor {
    background-color: #e6faf9;
    .title-font {
        color: #6d9f9d;
    }
    &:after {
        border-top: 40px solid #e6faf9;
    }
}

#team-training {
    background-color: #f5f3ff ;
    .title-font {
        color: #8e81bb;
    }
    &:after {
        border-top: 40px solid #f5f3ff ;
    }
}

#leadership-training {
    background-color: #f1fef0 ;
    .title-font {
        color: #51b748;
    }
}