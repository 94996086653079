.select-option{
    select{
        color: #888;
    }
    option:not(:checked) { 
        color: #333;
    }
    // select{
    //     &:focus {
    //         font-size: 14px;
    //         color: #5b5b5b;
    //     }
    // }
}