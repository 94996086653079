.donars_section {
  padding-bottom: 20px;
  float: left;
  width: 100%;
  margin: 0;
  .donor-listing {
    max-height: 280px;
    overflow-y: auto;
  }
  .donar_header {
    position: relative;
    padding: 7px 15px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: #dfdfdf none repeat scroll 0 0;
    h2 {
      margin: 0;
      color: #0085ad;
      font-size: 16px;
    }
  }
  .donar_block {
    padding: 5px 75px 5px 5px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .donor_info {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      text-align: center;
      .alias {
        margin: 0;
      }
      .message {
        font-size: 10px;
        font-weight: 300;
        color: #8a8b8c;
        margin: 5px 0 0 0;
      }
    }
    .amount {
      font-size: 14px;
      color: #0085ad;
      margin: 0;
      min-width: 52px;
      text-align: center; 
    }
    img.trophy {
      float: right;
    }
  }
  .donar_block:nth-child(odd) {
    background-color: white;
  }
  .donar_block:nth-child(even) {
    background-color: #daf4fd;
  }
}