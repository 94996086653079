.recurring-wrap{
    overflow-x: hidden;
    #report-table-header{
        padding: 10px 20px;
    }
    .donation-success{
        margin-left: 15px;
    }
    .donation-failed{
        margin-left: 15px;
    }
    .total-expected{
        margin-right: 15px;
    }
}
.color-green{
    color: rgb(104, 243, 104);
  }
.color-red{
    color:red
}
.table-font td{font-size: 14px;}