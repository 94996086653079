@import '../../index.scss';

.prospect-table-container {
  font-family: $proxima-nova;
  width: 100%;
  overflow-x: auto;
  .sort-icon {
    color: #008eb5;
    padding-left: 5px;
    font-size: 18px;
  }
  th {
    white-space: nowrap;
    text-align: center;
  }
  .prospectBtn {
    background: #aaabaa;
    color: white;
    border-radius: 0;
    font-size: 12px;
    white-space: noWrap;
    padding: 4px;
    &:hover {
      background: #aae7f8;
      color: #30748c;
    }
  }
  .checkbox {
    color: #008eb5;
    margin: 0;
  }
}

.pldj-pagination {
  position: fixed;
  width: 82%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}
