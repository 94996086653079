
@import '../../index.scss';

.videobox {
    background: #dbf7fe;
    padding: 30px 0px;
    .video_box_header{
      .heading_tag_left{
        padding-right: 80px;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 0px;
        color: $white;
        font-family: $Roboto;
        font-weight: 900;
        font-size: 24px;
        height: 55px;
        line-height: 50px;
      }
      .heading_tag_left--custom{
        padding-right: 60px!important;
        width: 70%;
        margin: auto;
        margin-bottom: 50px;
      }
      .heading_tag_right{
        padding-left: 80px;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 0px;
        color: $white;
        font-family: $Roboto;
        font-weight: 900;
        font-size: 24px;
        height: 55px;
        line-height: 50px;
      }
      .heading_tag_right--custom{
        padding-left: 60px!important;
        width: 70%;
        margin: auto;
        margin-bottom: 50px;
      }
    }
    .video_iframe{ 
        padding: 0px 40px;
        margin-top: 50px;
        iframe.border_frame {
        border: 3px solid $white;
      }
    }
  }

  .testimonial {
    background: #e4f9ff;
    padding: 30px 0px;
    #quote-carousel {
      padding: 0 10px 30px 10px;
      margin-top: 30px;
      text-align: center;
      p{
        position: relative;
        font-size: 19px;
        font-family: $Roboto-slab;
        font-weight: 300;
        padding: 60px 75px 15px;
        img.left_quote {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0.1;
          max-width: 123px;
        }
        img.right_quote {
          position: absolute;
          right: 0;
          bottom: -25px;
          opacity: 0.1;
          max-width: 123px;
        }
        span{
          font-family: $Roboto-slab;
          font-weight: 400;
          font-size: 19px;
          color: $dark_grey;
          &.testimonial_price{
            color: $light_blue;
            font-size: 26px;
            font-family: $Roboto-slab;
            font-weight: 700;
          }
        }
      }
      .small-custom{
        font-family: $Open-Sans;
        font-weight: 300;
        font-size: 80%!important;
      }
      .carousel-indicators {
        right: 50%;
        top: auto;
        bottom: -35px;
        margin-right: 0;
        li{
          display: inline-block;
          height: 5px;
          width: 20px;
          margin: 1px;
          text-indent: -999px;
          cursor: pointer;
          background: #d7d7d7;
          border: 1px solid #d7d7d7;
          border-radius: 10px;
          &.active {
            background: $light_blue;
            height: 5px;
            width: 20px;
            margin-bottom: 1px;
            border-radius: 10px;
          }
        }
    }
    .carousel-control.left, .carousel-control.right{
      display: none;
    }
  
    }
  }