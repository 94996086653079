.calc_donation {
  border: 10px solid #ea8502;
  background: #e3ffb0;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 0 15px;

  .donation_values {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    position: relative;

    .symbol {
      height: 30px;
      width: 30px;
      border: 2px solid #7e8f03;
      color: #7e8f03;
      border-radius: 50%;
      background: #fff;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      position: absolute;
      top: 50px;
    }
    .cross {
      z-index: 2;
      left: 18%;
    }
    .equal {
      right: 25%;
    }

    .number_month {
      z-index: 2;
      .calc_wrapper {
        position: relative;
        height: 40px;
        button {
          height: 40px;
          line-height: 20px;
          width: 25px;
          min-width: 25px;
          background: #7e8f03;
          color: #fff;
          font-size: 20px;
        }
        .minus {
          border-radius: 4px 0 0 4px;
        }
        .plus {
          border-radius: 0 4px 4px 0;
        }
        input { 
          height: 40px;
          border: none;
          border-radius: 0;
          background: #fff;
          padding: 0;
          width: 60px;
          text-align: center;
          font-size: 18px
        }
      }
    }
    .number_month:after {
      content: '';
      display: block;
      height: 3px;
      width: 400px;
      z-index: -1;
      top: 65px;
      left: 15%;
      background: #7d8f03;
      position: absolute;
    }
    .month_per_donation {
      z-index: 2;
      .monthly-donation {
        position: relative;
        input {
          padding-left: 48px;
          width: 62px;
          text-align: left;
        }
        span {
          font-size: 18px;
          top: 20%;
          left: 33px;
          position: absolute;
        }
      }
      input {
        height: 40px;
        border: none;
        border-radius: 0;
        background: #fff;
        padding: 0;
        width: 100px;
        text-align: center;
        font-size: 18px
      }
    }
    .total_amount {
      z-index: 2;
      input {
        height: 40px;
        border: none;
        border-radius: 0;
        background: #fff;
        padding: 0;
        width: 100px;
        text-align: center;
        font-size: 18px
      }
    }
  }

  .doubling {
    margin-right: 10px;
    position: absolute;
    top: 50px;
    right: 21%;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #7e8f03;
    color: #000;
    font-weight: 700;
    background: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    span {
      position: absolute;
      font-size: 16px;
      top: 0px;
      background: #e3ffb0;
      border-radius: 50%;
    }
    .cr {
      left: -5px;
    }
    .eq {
      right: -5px;
    }
  }

  .total_receive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    label {
      text-align: center;
      font-weight: 500;
    }
    .calculate {
      display: flex;
      align-items: center;
      h1 {
        margin: 0;
      }
    }
  }
}

@media (max-width: 1199px) {
  .calc_donation {
    .donation_values {
      .number_month:after {
        width: 320px;
      }
      .cross {
        z-index: 2;
        left: 21%;
      }
    }
    .doubling {
      right: 18%;
    }
  }
}

@media (max-width: 991px) {
  .calc_donation {
    height: auto;
    .donation_values {
      width: 300px;
      height: auto;
      flex-direction: column;
      padding: 20px 0;
      .number_month {
        padding: 10px 0 30px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .calc_wrapper {
          width: 110px;
        }
        p {
          width: 175px;
          text-align: right;
          margin: 0;
        }
      }
      .number_month:after {
        content: '';
        display: block;
        height: 170px;
        width: 3px;
        z-index: -1;
        top: 60px;
        left: 240px;
        background: #7d8f03;
        position: absolute;
      }
      .cross {
        top: 75px;
        left: 227px;
      }
      .month_per_donation {
        padding: 10px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          width: 175px;
          text-align: right;
          margin: 0;
        }
      }
      .total_amount {
        padding: 10px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 110px;
        }
        p {
          width: 175px;
          text-align: right;
          margin: 0;
        }
      }
      .doubling {
        left: 227px;
        top: 230px;
      }
      .total_receive {
        padding: 40px 0 10px 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .calculate {
          width: 110px;
          text-align: center;
          h1 {
            width: 100%;
          }
        }
        label {
          width: 175px;
          text-align: right;
        }
      }
    }
  }
}