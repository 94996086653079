@import '../../index.scss';

.prospect-main {
  font-family: $proxima-nova;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 60px;
  .section1 {
    max-width: 30%;
    min-width: 25%;
    .title {
      margin: 10px 0 10px 0;
      color: #ddd;
      font-size: 22px;
      text-transform: capitalize;
    }
    .filter-title {
      padding: 5px 15px;
      background: #1091b5;
      font-size: 18px;
      color: #fff;
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
    .sort-radio {
      width: 100%;
      position: relative;
      .filters {
        width: 80%;
        .radio-item {
          margin: 0;
          svg {
            color: #1091b5;
          }
          span {
            font-size: 16px;
            font-weight: 300;
            font-family: $proxima-nova;
            white-space: nowrap;
            &:first-child {
              padding: 3px;
              input {
                margin: 0;
              }
            }
          }
        }
      }
      .user-list-btn {
        position: absolute;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #1091b5;
        right: 0;
        bottom: 0;
        &:hover {
          cursor: pointer;
        }
        i {
          font-size: 18px;
          color: #fff;
          line-height: 40px;
          text-align: center;
          width: 100%;
        }
      }
    }
    @media (min-width: 991px) and (max-width: 1200px) {
      min-width: 30%;
      .title {
        font-size: 16px;
      }
    }
  }

  .section2 {
    width: 30%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 10px 0 10px 0;
      font-size: 22px;
      color: #3b3b3b;
      span {
        color: #008eb5;
        margin-left: 15px;
      }
    }
    .upload-btn {
      width: 225px;
      .span {
        padding: 10px;
        font-family: $proxima-nova;
        margin: 0;
        font-size: 16px;
        justify-content: flex-start;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        background: #d3e8e3;
        span {
          color: #3b7862;
          font-family: $proxima-nova;
          i {
            color: #3b7862;
            font-size: 22px;
            margin-right: 10px;
          }
        }
      }
    }
    button {
      margin: 0;
      font-size: 16px;
      justify-content: flex-start;
      padding: 10px;
      border-radius: 0;
      width: 225px;
      box-shadow: none;
      &:nth-child(2) {
        background: #aae7f8;
        margin-bottom: 5px;
        span {
          color: #30748c;
        }
      }
      &:nth-child(3) {
        margin-bottom: 5px;
        background: #d3e8e3;
        span {
          color: #3b7862;
          i {
            color: #3b7862;
          }
        }
      }
      span {
        &:hover {
          cursor: pointer;
        }
        i {
          font-size: 22px;
          color: #30748c;
          margin-right: 10px;
        }
        font-family: $proxima-nova;
      }
    }
    @media (min-width: 991px) and (max-width: 1200px) {
      .title {
        font-size: 16px;
        text-align: center;
      }
      .upload-btn {
        width: 100%;
        span {
          font-size: 15px;
        }
      }
      button {
        width: 100%;
        text-align: left;
        font-size: 15px;
      }
    }
  }
}

#user-popover {
  .close-btn {
    width: 100%;
    height: 25px;
    margin: 10px 0;
    i {
      padding: 5px;
      float: right;
      font-size: 20px;
      color: #ddd;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .search-user {
    width: 100%;
    padding: 0 10px;
    .input {
      width: 100%;
      background: #fff;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      input {
        padding: 8px 6px;

      }
    }
  }
  .list {
    .item {
      &:hover {
        cursor: pointer;
        background: #ddd;
      }
    }
  }
}