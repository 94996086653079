#description {
  .frame-container {
    position: relative;
    background: rgba(0, 0, 0, 0) url(../../assets/images/product-shadow.png) no-repeat scroll center center / cover;
    @media (max-width: 991px) {
      width: 80%;
      height: 400px;
    }
    @media (max-width: 600px) {
      width: 100%;
      height: 200px;
    }
    .frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 25px;
    }
  }
  .project-header {
    // background: rgba(0, 0, 0, 0) url(../../assets/images/product-shadow.png) no-repeat scroll center center / cover;
    background-color: #fff;
    padding:10px!important;
    height:100%;
    width: 100%;
  }
  .video-container {
    z-index: 2;
    position: relative;
  
    .fluid-width-video-wrapper {
      width: 100%;
      position: relative;
      padding: 0;
  
      iframe {
        max-width: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: bottom;
        border: none !important;
      }
    }
  }
}