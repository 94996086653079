#team-table-header {
  background: #008eb5;
  padding: 10px 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .search {
    position: relative;
    .input {
      background: #fff;
      font-size: 14px;
      border-radius: 4px;
      input {
        padding: 8px 20px 8px 8px;
      }
    }
    i {
      position: absolute;
      color: #bbb;
      right: 8px;
      top: 8px;
    }
  }
  .table-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select {
      margin-left: 5px;
      font-size: 14px;
      background: #fff;
      border-radius: 4px;
      height: 32px;
      color: #acacac;
      padding: 2px 0;
      select {
        padding: 8px 32px 8px 8px;
        height: auto;
      }
    }
  }
}