@import "../../index.scss";

.itemize {
  .top-section {
    background: #9fbe61;
    padding: 10px 10px;
    justify-content: center;
    .donate-actions {
      margin-right: 15px;
      .input {
        width: 200px;
        position: relative;
        display: flex;
        align-items: center;
        label {
          position: absolute;
          color: #4a4a4a;
          width: 40px;
          height: 40px;
          top: 0;
          left: 0;
          background: #ddd;
          text-align: center;
          font-size: 22px;
          line-height: 40px;
        }
        input {
          padding: 8px 8px 8px 50px;
          border: none;
          font-size: 22px;
          font-weight: 500;
          color: #4a4a4a;
          height: 40px;
          width: 100%;
          text-align: center;
          color: #5585b5;
        }
      }
      .type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        width: 200px;
        .button {
          background: #383838;
          width: 90px;
          height: 40px;
          margin: 0;
          span:first-child {
            padding: 2px;
          }
          span:nth-child(2) {
            color: #fff;
            font-size: 13px;
          }
        }
      }
    }
    .donation-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .heading {
        align-items: center;
      }
      .img-container {
        background: #fff;
        border: 3px solid #49769e;
        box-shadow: 0px 0px 5px 0px rgba(196, 196, 196, 0.75);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 115px;
          height: 115px;
          border-radius: 50%;
        }
      }
      .details {
        padding-left: 10px;
        color: #fff;
        font-size: 22px;
        .title {
          font-family: $avant-guard;
          text-transform: uppercase;
        }
      }
      .currency-selector {
        float: right;
        color: #4a4a4a;
        border: 2px solid #fff;
        label {
          background: #fff;
          text-align: center;
          width: 100%;
        }
        .select {
          background: #4a4a4a;
          text-align: center;
          width: 100%;
          select {
            color: #fff;
          }
        }
      }
    }
  }
  .mobile-donation {
    align-items: center;
    label {
      text-align: center;
      font-size: 18px;
      color: #fff;
      width: 75%;
    }
    .details {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .img-container {
        background: #fff;
        border: 3px solid #49769e;
        box-shadow: 0px 0px 5px 0px rgba(196, 196, 196, 0.75);
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 95px;
          height: 95px;
          border-radius: 50%;
        }
      }
    }
  }

  .campaign-items {
    width: 100%;
    background: #fff;
    font-family: $ITC-Avant-Demi;
    .item {
      min-height: 150px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .donated-container {
        display: flex;
        justify-content: center;
        width: 20%;
        .donated {
          text-align: center;
          font-family: $avant-guard;
          font-size: 12px;
          background: #5585b5;
          color: #fff;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: flex;
          align-items: center;
        }
      }
      .left {
        width: 20%;
        align-items: center;
        justify-content: center;
        background: #5585b5;
        padding: 15px 5px;
        span {
          font-size: 12px;
          text-align: center;
          text-transform: capitalize;
          color: #fff;
        }
        input {
          width: 65%;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          padding: 5px;
          border: none;
          color: #5585b5;
        }
      }
      .center {
        width: 60%;
        .img-container {
          width: 30%;
          background: #fff;
        
          img {
            max-width: 100%;
            height: auto;
            padding: 2px;
          }
        }
        .details {
          padding-left: 10px;
          width: 70%;
          .title {
            font-family: $avant-guard;
            font-size: 22px;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            p {
              font-family: $avant-guard;
              text-transform: capitalize;
            }
            span {
              align-items: baseline;
              margin: 0 5px;
              p {
                margin-left: 10px;
              }
            }
          }
          .desc {
            font-family: $avant-guard;
            max-height: 60px;
            overflow-y: auto;
          }
          .show-more {
            color: #5585b5;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .right {
        font-family: $avant-guard;
        width: 18%;
        align-items: center;
        .total {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .mobile-item {
      padding: 5px;
      justify-content: center;
      .center {
        justify-content: center;
        .img-container {
          background: #fff;
          box-shadow: 0px 0px 5px 0px rgba(196, 196, 196, 0.75);
          width: 120px;
          height: 100px;
          img {
            max-width: 100%;
            padding: 2px;
          }
        }
        .details {
          width: 225px;
          padding: 2px 0;
          justify-content: space-between;
          .title {
            font-size: 18px;
            margin-left: 15px;
            text-transform: uppercase;
          }
          .price {
            font-size: 18px;
            margin-left: 15px;
            align-items: baseline;
            p {
              font-size: 10px;
              margin-left: 10px;
            }
          }
          .donation {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #5585b5;
            color: #fff;
            padding: 0 10px;
            height: 45px;
            span {
              font-size: 12px;
            }
            input {
              height: 40px;
              border: none;
              color: #5585b5;
              width: 100px;
              text-align: center;
              font-size: 14px;
            }
          }
        }
      }
      .desc-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px 0;
      }
      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px 0;
        p {
          padding: 0 5px;
        }
      }
    }
    .mobile-item:nth-child(even) {
      background: #f7f7f7;
    }
  }
}

@media (max-width: 767px) {
  .itemize{
   .campaign-items{
      .mobile-item--custom{
        .flex-d{
          display: block;
        }
      }
    }  
  } 
}