#report-table-header {
  background: #7c6a9c;
  padding: 5px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .search {
    position: relative;
    width: 250px;
    .input {
      width: 100%;
      background: #fff;
      font-size: 14px;
      border-radius: 4px;
      input {
        padding: 8px 20px 8px 8px;
      }
    }
    i {
      position: absolute;
      color: #bbb;
      right: 8px;
      top: 8px;
    }
  }
  .table-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select {
      margin-left: 5px;
      font-size: 14px;
      background: #fff;
      border-radius: 4px;
      height: 32px;
      color: #461b1b;
      padding: 2px 0;
      select {
        padding: 8px 32px 8px 8px;
        height: auto;
      }
    }
  }
}
