.snack-wrapper {
  width: 100%;
  .snack {
    max-width: 100%;
  }
}

@media (max-width: 380px) {
  .snack-wrapper--custom{
    .snack{
      padding: 6px 10px;
    }
    .snack>div:first-child{
      width: 86%;
    }
    .snack>div:nth-child(2){
      padding-left: 0;
    }
  }
}