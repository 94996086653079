@import '../../index.scss';

.backdrop {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.pre-campaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  h1 {
    color: #80c341;
    font-size: 30px;
    font-weight: 600;
  }

  h2 {
    color: #80c341;
    font-size: 25px;
    font-weight: 500;
  }

  p {
    color: #fff;
    font-weight: 600;
    margin: 0;
    .green-text {
      color: #80c341;
      font-weight: 400;
    }
  }

  .main-section {
    padding-top: 5px;
    margin: 0 auto;
    width: 90%;
    background: #34495e;
    position: relative;
    input {
      width: 90%;
      background: transparent;
      border: none;
      text-align: center;
      font-weight: 300;
      color: #fff;
    }

    input::placeholder {
      color: #fff;
    }

    .header {
      font-size: 20px;
      height: 30px;
      margin: 0;
      font-weight: 300;
      color: #fff;
    }

    .footer {
      font-size: 15px;
      height: 20px;
      margin: 0;
      font-weight: 400;
      color: #fff;
    }

    .project-header {
      background: rgba(0, 0, 0, 0) url(../../assets/images/product-shadow.png) no-repeat scroll center center / cover;
      padding: 5px 25px;
      width: 70%;
      margin: 10px auto;
      .video-container {
        position: relative;
        .fluid-width-video-wrapper {
          width: 100%;
          position: relative;
          padding: 0;
          iframe {
            max-width: none;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            vertical-align: bottom;
            border: none !important;
          }
        }
      }
    }

    .banner {
      background: #89bb49;
      height: 40px;
      width: 102%;
      color: #fff;
      left: -7px;
      text-align: center;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        position: absolute;
        top: 40px;
        left: 0px;
        width: 8px;
      }
      .right {
        position: absolute;
        top: 40px;
        right: 0px;
        width: 8px;
      }
    }

    .precampaign-calculator {
      width: 100%;
      height: 150px;
      background: #1c2c3c;
      padding: 40px 15% 0px 15%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-box {
        background: #fff;
        width: 190px;
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #89bb49;
        }
        .currency {
          font-size: 30px;
          color:#89bb49;
          width: 20%;
          margin: 0;
          float: left;
          text-align: right;
        }
        input {
          height: 42px;
          background: transparent;
          border: none;
          font-size: 30px;
          color: #000;
          width: 50%;
          float: left;
          text-align: right;
        }
        input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .decimal {
          font-size: 30px;
          color:#000;
          width: 30%;
          margin: 0;
          float: left;
          text-align: left;
        }
      }

      .summary {
        max-width: 250px;
        padding: 0 10px;
        .organization {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          border-bottom: 2px solid #fff;
          line-height: 18px;
        }
        .calculate {
          font-weight: 600;
          font-size: 18px;
          color: #fff;
          align-items: center;
          margin-top: 5px;
          .multiplier {
            float: left;
            background: #80c341;
            padding: 5px;
            border: 1px solid #fff;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            position: relative;
            .cross {
              font-weight: 400;
              font-size: 18px;
              color: #fff;
              position: absolute;
              left: 5px;
              top: -3px;
            }
            .value {
              font-weight: 600;
              font-size: 18px;
              color: #fff;
              position: absolute;
              left: 15px;
              top: 5px;
            }
          }
        }
      }

      button {
        background: #89bb49;
        font-size: 20px;
        font-weight: 300;
        color: #fff;
        border: none;
        height: 50px;
        line-height: 20px;
      }
    }
  }

  .countdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    .item {
      max-width: 100px;
      height: 100px;
      position: relative;
      .svg-text {
        position: absolute;
        z-index: 999;
        color: #fff;
        .remaining {
          font-size: 20px;
          font-weight: 400;
          height: 25px;
        }
        .datetime {
          font-size: 10px;
        }
      }
      .days {
        top: 27px;
        left: 37px;
      }
      .hours {
        top: 27px;
        left: 33px;
      }
      .mins {
        top: 27px;
        left: 29px;
      }
      .sec {
        top: 27px;
        left: 28px;
      }

      .CircularProgressbar-background { 
        fill: #34495e; 
      }
      .CircularProgressbar-trail {
        stroke: #314559;
      }
    }
  }
}

@media (max-width: 1300px) {
  .pre-campaign {
    .main-section {
      .precampaign-calculator {
        padding: 40px 70px 0 70px;
      }
    }
  }
}

@media (max-width: 1180px) {
  .pre-campaign {
    .main-section {
      .precampaign-calculator {
        padding: 40px 70px 0 70px;
      }
    }
  }
}

@media (max-width: 1060px) {
  .pre-campaign {
    .main-section {
      .precampaign-calculator {
        padding: 40px 40px 0 40px;
      }
    }
  }
}

@media (max-width: $iproLand) {
  .pre-campaign {
    .main-section {
      .project-header {
        padding: 5px 35px;
      }
    }
  }
}

@media (max-width: $iLand) {
  .pre-campaign {
    .main-section {
      .banner {
        width: 103%;
        left: -8px;
      }
      .precampaign-calculator {
        padding: 40px 100px 0 100px;
      }
    }
  }
}

@media (max-width: 820px) {
  .pre-campaign {
    .main-section {
      .precampaign-calculator {
        padding: 40px 20px 0 20px;
      }
    }
  }
}

@media (max-width: $iPort) {
  .pre-campaign {
    width: 85%;
    .main-section {
      .banner {
        width: 103%;
        left: -9px;
      }
      .precampaign-calculator {
        padding: 40px 20px 0 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .pre-campaign {
    width: 100%;
    .countdown {
      margin: 0 -12px;
    }
    .main-section {
      .header {
        font-size: 18px;
      }
      .project-header {
        padding: 5px 15px;
      }
      .banner {
        width: 104.5%;
        left: -10px;
        padding: 0;
        .left {
          width: 10px;
        }
        .right {
          width: 10px;
        }
      }
      .precampaign-calculator {
        display: inline-block;
        height: 200px;
        .text-box {
          margin: 10px;
          width: 40%;
          float: left;
          .title {
            font-size: 16px;
          }
          .currency {
            font-size: 25px;
          }
          input {
            height: 35px;
            font-size: 25px;
          }
          .decimal {
            font-size: 25px;
          }
        }
        .summary {
          margin: 10px;
          width: 45%;
          float: right;
          .organization {
            font-size: 14px;
          }
        }
        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .pre-campaign {
    .main-section {
      .header {
        font-size: 16px;
      }
      .project-header {
        padding: 5px 15px;
        margin: 5px auto;
        width: 100%;
      }
      .banner {
        width: 360px;
        left: -11px;
        padding: 0;
        .left {
          width: 10px;
        }
        .right {
          width: 10px;
        }
      }
      .precampaign-calculator {
        height: 175px;
        .text-box {
          margin: 5px;
          .title {
            font-size: 14px;
          }
        }
        .summary {
          margin: 5px;
          .organization {
            font-size: 14px;
          }
          .calculate {
            .multiplier {
              height: 25px;
              width: 25px;
              .cross {
                font-size: 13px;
                left: 4px;
              }
              .value {
                font-size: 16px;
                top: 2px;
                left: 11px;
              }
            }
          }
        }
      }
    }
  }
}
