.team-management-form {
  background: #ebf8fd;
  .title {
    text-align: center;
    font-size: 18px;
  }
  .section {
    display: flex;
    flex-direction: column;
    padding: 1%;
    justify-content: flex-start;
    .page-name-label {
      align-items: center;
      background: #fff;
      border-radius: 4px;
      .baseurl {
        font-weight: 500;
      }
      .checkout_input {
        input {
          padding-left: 0;
        }
      }
    }
    .file-input {
      padding: 5px;
    }
  }
  .label {
    color: black;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
  }
  .input {
    background: white;
  }
  .text-area {
    background: #fff;
    div {
      padding: 0;
      line-height: normal;
    }
    textarea {
      padding: 6px;
      font-size: 14px;
    }
  }
  .buttonContainer {
    .button {
      color: white;
      font-size: 14px;
    }
  }
  .checkout_input {
    border-radius: 4px;
    border: none;
    width: 100%;
    p {
      font-size: 13px;
    }
    input {
      padding-left: 10px;
      background: #fff;
      font-size: 14px;
      border-radius: 4px;
    }
    :after {
      border: none !important;
    }
    :before {
      border: none !important;
    }
  }
}
