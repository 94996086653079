.campaign_wrapper {
  background-repeat: no-repeat;
  margin: auto;
  font-family: 'roboto';
  min-height: 40vh;
}

.green-bg {
  background-image: linear-gradient(rgb(166, 243, 184), rgb(216,249,246));
}

.blue-bg {
background-image: linear-gradient(rgb(200, 244, 255), rgb(189, 228, 228));
}

.content {
.camp-img {
  display: block;
  margin-top: 30px;
}
#camp-image {
  margin-bottom: 20px;
}
.instruction {
  display: flex;
  .instruction-img {
    height: 250px;
  }
  @media (max-width: 991px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.block {
  display: block;
  clear: both;
}
.sponsor-img {
  margin-top: 20px;
}
.mobile-clock {
  margin-bottom: 50px;
  display: none;
  @media (max-width: 991px) {
    display: block;
    .goals {
      .goal-point {
        text-transform: uppercase;
        color: #909090;
        font-size: 24px;
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }
    .time-remaining {
      max-width: 200px;
      margin: 0 auto;
      svg {
        height: 155px;
        top: 65px;
      }
      .t-labels {
        right: 24%;
        top: 60%;
        line-height: 35px;
        .hr {
          font-size: 18px;
        }
        .min {
          font-size: 13px;
        }
        .sec {
          font-size: 8px;
        }
      }
      .t-timer {
        left: 42%;
        top: 60%;
        line-height: 35px;
        .hr {
          font-size: 28px;
        }
        .min {
          font-size: 24px;
        }
        .sec {
          font-size: 16px;
        }
      }
    }
    .bouns_goal_div {
      margin-bottom: 20px;
      text-align: center;
    }
    .bouns-goal-point {
      text-transform: uppercase;
      color: #579e19;
      font-size: 24px;
      margin-top: 20px;
    }
  }
}
}

@media (max-width: 767px) {
.content {
  .camp-img {
    display: none;
  }
}
}

#description {
.cont_mobile_version {
  display: block;
  padding: 0 15px;
  img {
      margin-bottom: 50px;
      margin-top: 30px;
        width: 100%;
        height: auto;
    }
    a {
        background: #0085ad none repeat scroll 0 0;
        border-radius: 4px;
        color: #ffffff;
        float: left;
        font-size: 14px;
        text-decoration: none;
        margin: 0;
        padding: 10px 0;
        text-align: center;
        width: 100px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}
}

.project-inner-content {
  padding: 30px 0 20px 0;
  h1 {
    color: #2995c3;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    padding: 0 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  h2 {
    color: #2995c3;
    text-transform: uppercase;
    text-align: left;
    padding: 0 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  h3 {
    color: #2995c3;
    text-transform: uppercase;
    text-align: left;
    padding: 0 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  .super_button {
      background: #1d92b6;
      border: 1px solid #1d92b6;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      padding: 8px 0;
      width: 35%;
      margin-right: 20px;
      text-align: center;
      float: right;
      display: none;
      @media (max-width: 750px) {
          display: block;
      }
      @media (max-width: 500px) {
          width: 50%;
          display: block;
          float: none;
          margin: auto;
      }
  }
  .super_pop_div {
      width: 100% !important;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: flex-end;
      flex-flow: row wrap;
      clear: both;
      .super_donr {
          vertical-align: top!important;
          display: inline-block;
          margin: 0 10px 15px;
          position: relative;
          z-index: 1;
          text-align: center;
          width: 33%;

          img {
              max-width: 90px;
              float: left;
              width: 30%;
              vertical-align: bottom;
              height: auto;
          }
          p.donar_name {
              font-style: normal;
              font-weight: bold;
              padding-top: 8px;
              text-transform: uppercase;
              color: #686868;
          }
          p {
              display: inline-block;
              width: 60%;
              font-size: 16px;
              line-height: 16px;
              margin: 0;
              position: static;
              padding: 0 0 0 15px;
              text-align: left;
              color: #686868;
          }
      }
  }
}
.bouns_super_goal_new {
  background: rgba(0, 0, 0, 0) url(../../assets/images/bonus-round.png) no-repeat scroll 0 0;
  color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  font-size: 18px;
  margin: 15px 0;
  width: 100%;
  background-position: center;
  @media (max-width: 750px) {
      background-size: contain !important;
      font-size: 15px;
  }
  i {
      background: rgba(0, 0, 0, 0) url(../../assets/images/victory-icon.png) no-repeat scroll center center / contain;
      display: inline-block;
      height: 70px;
      position: relative;
      top: -2px;
      width: 70px;
      z-index: 9;
  }
}
