@import '../../../index.scss';

.mp-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #008eb5;
  padding: 10px 15px;
  .responsive-timer {
    display: flex;
    align-items: center;
    color: #fff;
    i {
      font-size: 28px;
      margin-right: 10px;
    }
    .timer {
      display: flex;
      flex-direction: column;
      .time {
        font-size: 24px;
        display: flex;
        flex-direction: row;
        span {
          margin: 0 5px;
        }
      }
      .labels {
        padding: 0 9px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }
  }

  .search-container {
    margin-top: 10px;
    position: relative;
    width: 100%;
    .search-box {
      background: #fff;
      border-radius: 4px;
      width: 100%;
      input {
        padding: 8px 25px 8px 8px;
        font-size: 14px;
      }
    }
    i {
      position: absolute;
      color: #bbbbbb;
      right: 10px;
      top: 10px;
    }
  }

  .select-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
    span {
      font-size: 14px;
      color: #fff;
      width: 80px;
    }
    .campaign-selectbox {
      padding: 5px;
      font-size: 14px;
      color: #008eb5;
      text-align: center;
      background: #fff;
      border-radius: 4px;
    }
  }
}