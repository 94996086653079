@import '../../index.scss';

footer {
  max-width: 100%;
  background: #5b5b5b;
  padding: 30px 0 0;
  box-shadow: inset 0px 0px 3px #111;
  .footer-wrapper {
    display: flex;
    .menu {
      width: 50%;
      ul {
        padding-left: 30%;
        @media (max-width: 767px) {
          padding-left: 0;
        }
        li {
          margin-bottom: 10px;
          a {
            color: #9ca8b4;
            font-size: 15px;
            font-weight: 500;
          }
          a:before {
            content: ">";
            padding-right: 8px;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
    }
    .subscribe {
      width: 50%;
      display: flex;
      flex-direction: column;
      .content {
        width: 100%;
        padding-left: 30%;
        @media (max-width: 767px) {
          padding-left: 0;
        }
        h4 {
          font-size: 20px;
          color: #bdc3c8;
          font-weight: 700;
          padding-bottom: 10px;
          border-bottom: 1px solid #3b3f44;
        }
        .form {
          input {
            width: 100%;
            margin-bottom: 10px;
            padding: 7px 10px;
            border-radius: 5px;
            border: 1px solid #fff;
          }
          span {
            color: #bebebe;
          }
          button {
            margin-top: 15px;
            float: right;
            background: #4da400;
            width: 140px;
            border: 2px solid #4da400;
            font-size: 17px;
            font-weight: 700;
            color: #000;
            height: 46px;
            border-radius: 5px;
          }
          button:hover {
            background: #fff;
          }
        }
      }
    }
  }
  hr {
    margin: 0;
    width: 85%;
    float: right;
    border-top: 1px solid #3b3f44;
    padding-bottom: 10px;
  }
  .image {
    img {
      max-width: 315px;
    }
  }
  .inner-footer {
    background: #343434;
    padding: 8px 15px;
    box-shadow: inset 0px 0px 5px #111;
    .inner-footer-items {
      margin: 0 5%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 16px;
        font-family: $Roboto;
        color: #9a9a9a;
        line-height: 36px;
        font-weight: 500;
      }
      .social-icons {
        i {
          font-size: 18px;
          margin-left: 15px;
          color: #9a9a9a;
        }
        i:hover {
          color: #76797c;
          cursor: pointer;
        }
      }
    }
  }
}