#settings {
  padding: 10px;
  .cache-btn {
    background: #ff4242;
    color: #fff;
    font-size: 16px;
    float: right;
    margin: 10px 60px;
    padding: 10px 15px;
    border: none;
  }
  .title{
    width: 100%;
    padding: 5px 0;
    margin-top: 20px;
    font-size: 24px;
    color: #008eb5;
    border-bottom: 3px solid #008eb5;
  }
}