.table-root {
  width: 100%;
  .table-body {
    overflow-x: auto;
    margin-bottom: 40px;
    .report-table {
      min-width: 1020px;
      .nb-white-space {
        white-space: nowrap;
      }
    }
  }
}


.pldj-pagination {
  position: fixed;
  width: 82%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}
