@import '../../index.scss';

.prospect-table-header {
  .header {
    font-family: $proxima-nova;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 60px;
    background: #008eb5;
    .selectUser-container {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      label {
        width: 50%;
      }
      .select-volunteer-btn {
        background: #d3e8e3;
        font-size: 14px;
        padding: 8px 6px;
        border-radius: 4px;
        color: #3b7862;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .filter-prospects {
      display: flex;
      justify-content: center;
      flex-direction: row;
      .search {
        position: relative;
        margin-right: 5px;
        .input {
          background: #fff;
          font-size: 14px;
          border-radius: 4px;
          padding: 2px 0;
          input {
            padding: 8px;
          }
        }
        i {
          position: absolute;
          color: #acacac;
          right: 10px;
          top: 10px;
        }
      }
      .filter {
        margin-left: 5px;
        background: #fff;
        border-radius: 4px;
        color: #008eb5;
        padding: 8px 6px;
        font-size: 14px;
        width: 85px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .display {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      width: 17%;
      .show {
        display: flex;
        align-items: center;
        flex-direction: row;
        .select {
          margin: 0 5px;
          background: #fff;
          font-size: 14px;
          color: #acacac;
          border-radius: 4px;
          padding: 2px 0;
          select {
            padding: 8px 32px 8px 8px;
            height: auto;
            &:focus {
              background: none;
            }
          }
        }
      }
    }
  }
}
