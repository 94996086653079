.notfound-wrapper {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  background-color: #41b449;
  padding: 2px 0;
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(102, 184, 211);
    width: 100%;
    padding: 20px;
    h2 {
      color: white;
    }
    p {
      font-weight: bold;
    }
  }
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 100%;
  }
  .featured-campaigns {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 25px 0;
    min-height: 400px;
  }
}
