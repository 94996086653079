.team_wrapper {
	background-image: linear-gradient(rgb(219,247,254), rgb(206,254,218));
  background-repeat: no-repeat;
  margin: auto;
  font-family: 'roboto';

  .content {
    .team-img {
      display: block;
      margin-top: 30px;
      width: 100%;
    }
    @media (max-width: 991px) {
      .team-img {
        display: none;
      }
    }
  }

  .team-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 20%;
    }
    h1 {
      color: #0085ad;
      font-size: 42px;
      font-weight: 600;
      text-align: right;
    }
  }

  .team-center {
    justify-content: center !important;
    flex-direction: column;
    .info {
      text-align: center;
    }
    h1 {
      text-align: center;
    }
    .descr {
      text-align: center;
    }
  }

  .member {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .info {
      min-width: 20%;
      img {
        height: 150px;
        border-radius: 50%;
      }
      h4 {
        font-size: 30px;
        margin-top: 20px;
        color: #0085ad;
        text-align: center;
      }
    }

    #description {
      width: 100%;
      .desc {
        padding: 0 20px;
        margin-top: 10px;
        p {
          color: #747474;
          text-align: center;
        }
      }
      .show-more {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin: 0;
          color: #0085ad;
        }
        img {
          margin: 0 5px;
        }
        p:hover {
          cursor: pointer;
          color: #15718d;
        }
      }
    }
  }

  .donor {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .icon {
      min-width: 20%;
      img {
        display: block;
        margin: 0 auto;
      }
      p {
        margin-top: 20px;
        font-weight: 600;
        color: #0085ad;
        text-align: center;
      }
    }
    #description {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .content {
    .team-title {
      display: block;
      text-align: center;
      h1 {
        font-size: 30px;
        text-align: center;
        margin: 10px 0;
      }
      img {
        width: 30%;
      }
    }
    .member {
      display: block;
      text-align: center;
      margin-top: 10px;
      .info {
        width: 100%;
        h4 {
          font-weight: 700;
        }
      }
    }
    .donor {
      display: block;
      text-align: center;
      .icon {
        width: 100%;
      }
    }
  }
}

@media (max-width: 450px) {
  .content {
    .team-title {
      h1 {
        font-size: 22px;
      }
      img {
        width: 40%;
      }
    }
    .member {
      .info {
        h4 {
          font-weight: 700;
          font-size: 22px;
        }
      }
    }
  }
}