@import '../../../index.scss';

.currency-dialog {
  top: -50% !important;
  text-transform: uppercase;
}

.dialog {
  .content-area {
    background: #ddf4fc;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    min-height: 500px;
    height: 100%;
    .currency-container {
      background: #fff;
      padding: 0 30px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-family: 'Century Gothic',CenturyGothic,AppleGothic,sans-serif;
      span {
        font-size: 18px;
      }
      label {
        margin: 0;
        color: #22bfe8;
      }
      i {
        margin-left: 10px;
        color: #22bfe8;
      }
      .currency {
        text-transform: uppercase;
      }
    }
    .total-donation {
      height: 50px;
      margin-top: 10px;
      background: #22bfe8;
      color: #fff;
      border-radius: 30px;
      width: 80%;
      display: flex;
      .total-donate-text {
        display: flex;
        align-items: center;
        background: #1885a2;
        border-radius: 30px;
        width: 50%;
        height: initial;
        text-align: center;
        font-size: 16px
      }
      .total-donate-amount {
        width: 50%;
        height: initial;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
      }
    }
  }

  .app-bar {
    top: 0;
    background: #62a863;
    .toolbar {
      justify-content: center;
      button {
        span {
          label {
            font-size: 22px;
            font-family: $ITC-Avant-Demi;
            margin: 0;
            margin-top: 5px;
          }
        }
        svg {
          background: #fff;
          color: #62a863;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
  }

  // .pre-defined {
  //   background: #22bfe8;
  //   margin-top: 0;
  //   .collapse-title {
  //     color: #fff;
  //     font-family: 'Century Gothic',CenturyGothic,AppleGothic,sans-serif;
  //     font-size: 18px;
  //     font-weight: 600;
  //     div {
  //       margin: 0;
  //     }
  //   }
  //   .collapsable {
  //     padding: 0;
      
  //   }
  // }
  .monthly-donation {
    width: 100%;
    .donation-values {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: flex-end;
      flex-flow: row wrap;
    
      .i-grey {
        background: #a9a9a9;
      }
      .i-normal {
        background: #22bfe8;
      }

      .item {
        min-height: 55px;
        min-width: 33.3%;
        border: 0.1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-size: 24px;
          font-family: $ITC-Avant-Bold;
          p {
            line-height: 15px;
            font-family: $ITC-Avant;
          }
        }
        label {
          margin: 0;
          .MuiTypography-root-202 {
            margin-top: 8px;
            text-align: left;
            min-width: 75px;
          }
          span {
            padding: 0;
          }
        }
        p {
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }

  .c-normal {
    border: 3px solid #22bfe8;
    .custom-amount {
      label {
        color: #22bfe8;
      }
    }
  }
  .c-grey {
    border: 3px solid #a9a9a9;
    .custom-amount {
      background: #a9a9a9;
      input {
        background: white;
      }
      label {
        color: #000;
      }
    }
  }

  .other {
    margin: 15px 15px 0px 15px;
    border-radius: 5px;
    .custom-amount {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 5px 20% 5px 55%;
        text-align: right;
        color: #000;
        font-size: 50px;
        border-radius: 5px;
        border: none;
      }
      .placeholder-label {
        position: absolute;
        top: 0;
        right: 7%;
        font-size: 30px;
        font-weight: 400;
        height: 50px;
        margin: 0;
        line-height: 65px;
      }
      .input-label {
        position: absolute;
        display: flex;
        align-items: center;
        width: 55%;
        height: 50px;
        top: 0;
        label {
          text-align: center;
          font-family: $ITC-Avant-Bold;
          line-height: 18px;
          margin: 5px 20% 0px 20%;
          .upper {
            font-size: 22px;
          }
        }
        p {
          font-weight: 700;
          font-size: 22px;
          margin: 0;
        }
      }
    }
    .type {
      background: #a9a9a9;
      .donation-type {
        label {
          margin: 0;
          svg {
            color: #22bfe8;
            background: #fff;
            border-radius: 50%;
          }
          span:nth-child(2) {
            color: #434343;
            margin-top: 5px;
            font-size: 18px;
            font-family: 'Century Gothic',CenturyGothic,AppleGothic,sans-serif;
          }
        }
      }
    }
  }

  .monthly-total {
    display: flex;
    align-items: center;
    background: #22bfe8;
    border-radius: 25px;
    width: 95%;
    height: 50px;
    margin-top: 15px;
    color: #fff;
    position: relative;
    .you-pay {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      background: #3C849F;
    }
    .calculate {
      width: 90%;
      z-index: 9;
      display: flex;
      align-items: baseline;
      justify-content: space-evenly;
      p {
        margin: 0;
      }
      label {
        font-size: 24px;
        margin: 0;
      }
      span {
        margin: 0;
        font-size: 18px;
      }
      .total-amount {
        font-size: 32px;
      }
    }
    img {
      position: absolute;
      right: 125px;
      top: 30px;
    }
  }

  .multiplier {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 80%;
    .multiply {
      width: 60px;
      height: 60px;
      box-shadow: 0px 5px 5px 0px rgba(97, 97, 97, 0.5);
      background: #22bfe8;
      border-radius: 50%;
      position: relative;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 45px;
        text-align: center;
        margin: 0 0 5px 5px;
        font-weight: 500;
      }
      span {
        position: absolute;
        font-size: 20px;
        font-family: $ITC-Avant-Demi;
        top: 15px;
      }
      .cross {
        left: 5px;
      }
      .equal {
        right: -1px;
      }
    }
    label {
      width: 90px;
      font-family: $ITC-Avant-Demi;
      margin: 0;
      text-align: center;
    }
    span {
      font-size: 20px;
      font-family: $ITC-Avant-Demi;
    }
    .calculated-total {
      height: 40px;
      line-height: 40px;
      color: #fff;
      background: #22bfe8;
      border-radius: 20px;
      font-size: 20px;
      margin-left: 10px;
      min-width: 30%;
      text-align: center;
    }
  }

  .title {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    h5 {
      font-weight: 700;
      font-family: $ITC-Avant-Bold;
      font-size: 20px;
      margin: 0;
      text-align: center;
    }
    label {
      font-family: $ITC-Avant-Demi;
    }
    img {
      position: absolute;
      top: 35px;
      right: 140px;
    }
  }

  .total {
    margin-top: 5px;
    width: 180px;
    font-size: 50px;
    font-weight: 700;
    border-radius: 40px; 
    background: #22bfe8;
    color: #fff;
    text-align: center;
    z-index: 9;
  }

  .footer {
    height: 60px;
    bottom: 0;
    top: auto;
    background: #62a863;
    button {
      font-weight: 700;
      font-size: 30px;
      font-family: $ITC-Avant-Bold;
      color: #fff;
    }
  }
}

@media (max-width: 991px) {
  .dialog {
    .monthly-total {
      img {
        right: 355px;
      }
    }
    .title {
      img {
        right: 45%
      }
    }
  }
}

@media (max-width: 768px) {
  .dialog {
    .monthly-total {
      img {
        right: 265px;
      }
    }
  }
}

@media (max-width: 650px) {
  .dialog {
    .title {
      img {
        right: 42%;
      }
    }
  }
}

@media (max-width: 414px) {
  .dialog {
    .monthly-total {
      img {
        right: 125px;
      }
    }
    .title {
      img {
        right: 40%;
      }
    }
  }
}

@media (max-width: 400px) {
  .dialog {
    .monthly-total {
      img {
        right: 110px;
      }
    }
  }
}

@media (max-width: 360px) {
  .dialog {
    .monthly-total {
      img {
        right: 105px;
      }
    }
  }
}