
@import '../../index.scss';

.special_offer {
    position: relative;
    .spacial_img img {
      width: 100%;
    }
    .head_section {
      position: absolute;
      top: 14%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      .heading_tag {
        padding-right: 80px;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 0px;
        color: $white;
        font-family: $Roboto;
        font-weight: 900;
        font-size: 24px;
        height: 55px;
        line-height: 50px;
      }
    }
    .special_offer_caption {
      position: absolute;
      top: 43%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      p {
        color: $black;
        font-size: 24px;
        margin-bottom: 0;
      }
      .campaign_price {
        color: $light_green;
        font-size: 44px;
        font-weight: 900;
      }
      .campaign_off{
        color: $light_green;
        font-size: 24px;
        margin-bottom: 0;
      }
      h3 {
        margin-top: 0;
        font-weight: 300;
        color: $dark_blue;
      }
      a.more_info_anchor {
        background: #77af47;
        color: $white;
        text-transform: uppercase;
        font-weight: 500;
        padding: 10px 25px;
        display: inline-block;
        border-radius: 12px;
        font-size: 16px;
      }
      .special_image{
        display: none;
      }
    }
  }