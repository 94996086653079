@import '../../index.scss';

#header-admin {
  font-family: $proxima-nova;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 5px 60px;
  .title {
    display: flex;
    align-items: center;
    button {
      background: #fff;
      padding: 8px;
    }
    .title-text {
      margin: 0;
      font-size: 20px;
      padding-left: 10px;
      b {
        font-weight: 700;
      }
      label {
        margin: 0;
      }
    }
  }
  .campaign-select {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    .text {
      padding-right: 10px;
    }
    .campaign-selectbox {
      background: #fff;
      color: #008eb5;
      font-size: 14px;
      width: 30%;
      border-radius: 4px;
      padding-left: 4px;
    }
  }
}
