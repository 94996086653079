aside {
  width: 30%;
  float: right;
  margin: auto;
  position: static;
  .sidebar-blue-btn {
    background: #0085ad;
    border: 2px solid #0085ad;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    float: left;
    font-size: 15px;
    line-height: 30px;
    margin: 15px 18% 0;
    padding: 0px 0 0px;
    text-transform: uppercase;
    width: 64%;
  }
  .side-clock {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .sidebar-blue-btn:hover {
    background-color: white;
    color: #0085ad;
  }
  @media (max-width: 1199px) {
    width: 35%;
  }
  @media (max-width: 995px) {
    width: 98%;
    margin: auto;
    float: none;
    margin-bottom: 300px;
  }
  @media (max-width: 750px) {
    margin-bottom: 70px;
  }

  .camp-img-resp {
    display: none;
  }

  @media (max-width: 767px) {
    .camp-img-resp {
      display: block;
    }
    .mobile-change-order {
      #social-widget {
        margin-bottom: 20px !important;
      }
    }
  }

  .goals {
    margin-bottom: 20px;
    .team-goals {
      .time-remaining {
        min-height: 280px;
      }
    }
    .bouns_goal_div {
      margin-bottom: 20px;
      text-align: center;
    }
    .bouns-goal-point {
      text-transform: uppercase;
      color: #579e19;
      font-size: 24px;
      margin-top: 20px;
      @media (max-width: 750px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .goal-point {
      text-transform: uppercase;
      color: #909090;
      font-size: 24px;
      margin-top: 15px;
      @media (max-width: 750px) {
        text-align: center;
        margin-bottom: 20px;
      }
      strong {
        position: relative;
      }
      .check:after {
        position: absolute;
        height: 40px;
        width: 30px;
        top: 5px;
        content: " ";
        right: 100%;
        background: rgba(0, 0, 0, 0) url(../../assets/images/green-tick.png)
          no-repeat;
        bottom: 0;
        background-size: 20px 20px;
        display: block;
      }
    }
  }

  #site-fb-comments {
    clear: both;
    float: left;
    width: 100%;
    margin: 20px 0 40px 0;
    @media (max-width: 750px) {
      display: none;
    }
    h4 {
      background: #f5f5f5 none repeat scroll 0 0;
      color: #686868;
      padding: 20px 20px 30px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 22px;
      margin: 0;
    }
    .textwidget {
      background: #fbfbfb;
      padding: 15px 25px;
      p {
        top: -40px;
        color: #686868;
        left: 0px;
        margin-bottom: -30px;
        position: relative;
        margin: 0 0 15px;
        display: block;
      }
      iframe {
        @media (max-width: 995px) {
          width: 560px !important;
        }
      }
    }
  }
}
