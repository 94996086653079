#appbar-tab-container{
  display: flex;
  flex-direction: row;
  background-color: #fff;
  .tabs {
    width: 100%;
  }
  .tab {
    width:25%;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    font-size: 14px;
    border-right: 1px solid rgb(83, 193, 232);
  }
  .close-icon {
    margin: 15px;
    color:#959595;
    &:hover {
      cursor: pointer;
    }
  }
}
