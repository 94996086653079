@import '../../index.scss';

.volunteer-form {
  .header {
    background: #fb434f;
    color: #fff;
    padding: 20px 10px;
    h3 {
      @media (max-width: 500px) {
        width: 100%;
      }
      width: 70%;
      margin: 0 auto;
    }
  }
  .add-volunteer {
    font-family: $proxima-nova;
    @media (max-width: 500px) {
      width: 100%;
    }
    width: 70%;
    margin: 10px auto;
    .title {
      padding: 15px 10px;
      justify-content: space-between;
      h2 {
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    p {
      margin: 10px;
      font-size: 15px;
    }
    .user-info {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px;
      p {
        margin: 0;
      }
      label {
        padding-bottom: 5px;
      }
      .c-pass {
        width: 100%;
        .input {
          width: 100%;
        }
      }
      .input {
        border-radius: 4px;
        p {
          font-size: 12px;
        }
        input {
          border: 1px solid #ddd;
          padding: 0 6px;
          font-size: 14px;
          height: 32px;
          margin-bottom: 5px;
        }
        :after {
          border: none;
        }
        :before {
          border: none;
        }
        &:hover {
          ::before {
            border: none !important;
          }
        }
      }
      .select {
        font-size: 14px;
        border: 1px solid #ddd;
        background: #f1f1f1;
        select {
          padding-left: 4px;
        }
      }
    }
    .user-info.hour-info {
      flex-direction: row;
      .select {
        width: 20%;
      }
    } 
    .btn-container {
      padding: 10px;
      text-align: right;
      button {
        color: #fff;
        font-size: 18px;
        padding: 5px 20px;
      }
      .submit-btn {
        background: #5bc473;
        &:hover {
          background: #a6f3b8;
        }
      }
      .cancel-btn {
        background: #fb434f;
        margin-right: 10px;
        &:hover {
          background: #aa2f37;
        }
      }
    }
  }
}
.formik-form, .formik-form-dialog {
    .error {
      color: #cb3837;
    }
    p {
      font-size: 12px;
    }
  }
  
  .docker {
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .redhead {
      padding: 10px;
      background-color: #fb434f;
      width: 100%;
      color: white;
      display: flex;
      padding-left: 200px;
      h2 {
        margin: auto 0;
        text-shadow: 0px 1px, 1px 0px, 1px 1px;
        letter-spacing: 1px;
        font-weight: 900px;
      }
    }
    .redhead-dialog {
      padding: 10px;
      background-color: #fb434f;
      width: 100%;
      color: white;
      display: flex;
      padding-left: 0px;
      h2 {
        margin: auto 0;
        text-shadow: 0px 1px, 1px 0px, 1px 1px;
        letter-spacing: 1px;
        font-weight: 900px;
      }
    }
    .team-form {
      .formik-form {
        display: flex;
        flex-direction: column;
        padding: 25px;
        margin-left: 200px;
        .row1 {
          display: flex;
          flex-direction: row;
          justify-content: left;
          label {
            font-size: 14px;
            font-weight: 500;
          }
          .left-insideRow {
            flex: 0.35;
            margin-right: 25px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .crop-container {
              width: 100%;
              height: 400px;
              position: relative;
            }
            .controls {
              margin-top: 10px;
            }
            .crop-btn {
              background-color: grey;
              border: none;
              border-radius: 5px;
              margin-top: 20px;
            }
            .field {
              border: none;
              background-color: #f1f1f1;
              padding: 8px 10px;
              margin-top: 10px;
            }
            .upload {
              border: none;
              margin-top: 5px;
              margin-bottom: 20px;
            }
            .btn-div {
              display: flex;
              margin-top: 10px;
              .btnSubmit {
                background-color: #3fb745;
                border: none;
                border-radius: 5px;
                padding: 5px 5px;
                width: 100px;
                color: white;
              }
              .btnCancel {
                text-align: center;
                background-color: #f74647;
                border: none;
                border-radius: 5px;
                padding: 5px 5px;
                width: 100px;
                color: white;
                margin-left: 10px;
              }
            }
          }
          .right-insideRow {
            flex: 0.35;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
  
            .field {
              border: none;
              background-color: #f1f1f1;
              padding: 8px 10px;
              margin-top: 10px;
            }
          }
        }
      }
      .formik-form-dialog {
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin-left: 0px;
        .row1 {
          display: flex;
          flex-direction: row;
          justify-content: left;
          .left-insideRow {
            flex: 1;
            margin-right: 5px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .crop-container {
              width: 100%;
              height: 400px;
              position: relative;
            }
            .controls {
              margin-top: 10px;
            }
            .crop-btn {
              background-color: grey;
              border: none;
              border-radius: 5px;
              margin-top: 20px;
            }
            .field {
              border: none;
              background-color: #f1f1f1;
              padding: 8px 10px;
              margin-top: 10px;
            }
            .upload {
              border: none;
              margin-top: 5px;
              margin-bottom: 20px;
            }
            .btn-div {
              display: flex;
              margin-top: 10px;
              .btnSubmit {
                background-color: #3fb745;
                border: none;
                border-radius: 5px;
                padding: 5px 5px;
                width: 100px;
                color: white;
              }
              .btnCancel {
                text-align: center;
                background-color: #f74647;
                border: none;
                border-radius: 5px;
                padding: 5px 5px;
                width: 100px;
                color: white;
                margin-left: 10px;
              }
            }
          }
          .right-insideRow {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-left: 5px;
            .field {
              border: none;
              background-color: #f1f1f1;
              padding: 8px 10px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .docker {
      padding: 5px 0px 5px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      .redhead {
        padding: 10px;
        background-color: #fb434f;
        width: 100%;
        color: white;
        display: flex;
        padding-left: 200px;
        h2 {
          margin: auto 0;
          text-shadow: 0px 1px, 1px 0px, 1px 1px;
          letter-spacing: 1px;
          font-weight: 900px;
        }
      }
      .team-form {
        .formik-form {
          padding: 10px;
          margin-left: 100px;
        }
      }
    }
  }
  
  @media (max-width: 650px) {
    .docker {
      padding: 5px 0px 5px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      .redhead {
        padding: 10px;
        background-color: #fb434f;
        width: 100%;
        color: white;
        display: flex;
        padding-left: 0px; //changed
        h2 {
          margin: auto 0;
          text-shadow: 0px 1px, 1px 0px, 1px 1px;
          letter-spacing: 1px;
          font-weight: 900px;
        }
      }
      .team-form {
        .formik-form {
          display: flex;
          flex-direction: column;
          padding: 15px;
          margin-left: 0px;
          .row1 {
            .left-insideRow {
              flex: 1;
              margin-right: 5px;
              margin-top: 10px;
              display: flex;
              flex-direction: column;
            }
            .right-insideRow {
              flex: 1;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .docker {
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      .redhead {
        padding: 0px;
        background-color: #fb434f;
        width: 100%;
        color: white;
        display: flex;
  
        h2 {
          font-size: 22px;
        }
      }
      .team-form {
        .formik-form {
          display: flex;
          flex-direction: column;
          padding: 25px;
          margin-left: 0px;
          .row1 {
            display: flex;
            flex-direction: column;
            justify-content: left;
            .left-insideRow {
              flex: 0.35;
              margin-right: 0px;
              margin-top: 10px;
              display: flex;
              flex-direction: column;
              .field {
                border: none;
                background-color: #f1f1f1;
                padding: 8px 10px;
                margin-top: 10px;
              }
              .upload {
                border: none;
                margin-top: 5px;
                margin-bottom: 20px;
              }
              .btn-div {
                display: flex;
                margin-top: 10px;
                .btnSubmit {
                  background-color: #3fb745;
                  border: none;
                  border-radius: 5px;
                  padding: 5px 5px;
                  width: 100px;
                  color: white;
                }
                .btnCancel {
                  text-align: center;
                  background-color: #f74647;
                  border: none;
                  border-radius: 5px;
                  padding: 5px 5px;
                  width: 100px;
                  color: white;
                  margin-left: 10px;
                }
              }
            }
            .right-insideRow {
              flex: 0.35;
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              .field {
                border: none;
                background-color: #f1f1f1;
                padding: 8px 10px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  
.left-insideRow{
  flex: 0.35 1;
  margin-right: 25px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.crop-container {
  width: 100%;
  height: 400px;
  position: relative;
}
.crop-btn {
  background-color: grey;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.title-form{
  width: 100%;
  padding: 5px 0;
  font-size: 20px;
  margin: 20px 10px 0 10px;
  text-transform: uppercase;
  color: #333;
  font-weight: 700;
  border-bottom: 2px solid #41b549;
}
.mb-0{
  margin-bottom: 0;
}
input[type="file"]{
  padding: 0!important;
}
input[type="file"]::-webkit-file-upload-button{
  height:32px;
  background: #41b549;
  border: 2px solid #41b549;
  color: #fff;
}
.campaignDayLabel>span{
  font-size: 16px;
  color: #333;
}
.inlineRadio{
  flex-wrap: nowrap!important;
  flex-direction: initial!important;
}
.volunteer-form .add-volunteer .user-info .input{
  width: 100%;
}
.ml-0{
  margin-left: 0!important;
}
.ml-0 span:nth-child(2){
  padding-left: 0!important;
}
.row-custom{
  display: flex;
}
.mt-5{
  margin-top: 5px;
}
.inlineRadio>label>span{
  padding: 0 12px;
  font-size: 16px;
}
.inlineCheckbox>fieldset>div>label>span{
  padding: 0;
}

@media(max-width: 575px) {
  .inlineRadio{
    flex-wrap:wrap!important;
  }
  .ml-0{
    margin-left: 30px!important;
  }
}
@media(max-width: 767px) {
  label.campaignDayLabel{
    margin-left: 0!important;
  }
}