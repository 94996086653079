@import '../../../index.scss';

.mp-access {
  font-family: $proxima-nova;
  .blue {
    color: #008eb5;
    background: #aae7f8;
    font-size: 14px;
    border-radius: 4px;
    &:hover {
      background: #aae7f8;
    }
  }
  .green {
    background: #d3e8e3;
    color: #3b7862;
    font-size: 14px;
    border-radius: 4px;
    &:hover {
      background: #d3e8e3;
    }
  }
  .content {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    &:first-child {
      padding: 0;
    }
    .sticky {
      position: fixed;
      width: 100%;
      z-index: 99;
      .navigate {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #008eb5;
        button {
          background: #ffe28b;
          color: #000;
          font-size: 14px;
          width: 30%;
        }
      }
    }
  }

  .prospect {
    display: flex;
    justify-content: center;
    padding-top: 175px;
    .prospect-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 375px;
      font-family: $proxima-nova;
      padding: 10px 0;
      .suggestion {
        background: #aae7f8;
        color: #008eb5;
        padding: 5px;
        font-size: 20px;
      }
      .prospect-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 10px;
        width: 100%;
        .select-status {
          border-radius: 4px;
          font-size: 14px;
          color: #fff;
          width: 50%;
          text-align: center;
          padding: 5px 0;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .p-row {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          .col {
            display: flex;
            align-items: center;
            width: 50%;
            padding: 0 5px;
            i {
              font-size: 16px;
              color: #008eb5;
              margin-right: 5px;
            }
            .data {
              font-size: 14px;
              width: 100%;
              .input {
                width: 100%;
                border: 1px solid #ddd;
                border-radius: 4px;
                input {
                  font-size: 14px;
                  padding: 5px;
                }
              }
            }
          }
        }
        .hidden-items {
          width: 100%;
        }
        button {
          padding: 8px;
          margin-top: 10px;
        }
      }
      .prospect-actions {
        width: 100%;
        display: flex;
        justify-content: space-around;
        button {
          width: 30%;
        }
      }
    }
  }
  .donation {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .title {
      width: 100%;
      padding: 5px;
      font-size: 22px;
      background: #008eb5;
      color: #fff;
      text-align: center;
    }
    .donation-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 375px;
      color: #bbb;
      padding: 0 10px;
      font-size: 16px;
      .input {
        border-bottom: 1px solid #bbb;
        input {
          font-size: 16px;
          padding: 6px 6px 6px 20px;
        }
      }
      .row1 {
        margin-top: 10px;
        width: 100%;
        .with-absolute {
          position: relative;
          label {
            position: absolute;
            top: 5px;
            left: 5px;
          }
        }
      }
      .row2 {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .or {
        background: #008eb5;
        border-radius: 50%;
        color: #fff;
        padding: 5px;
      }
      .row3 {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      .row4 {
        display: flex;
        width: 100%;
        .radio-group {
          flex-direction: row;
          justify-content: flex-start;
          .radio-btn {
            margin: 0;
            span {
              color: #bbb;
              font-size: 16px;
            }
          }
        }
      }
      .row5 {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .input {
          border: 1px solid #bbb;
          border-radius: 4px;
          font-size: 16px;
          padding: 0;
          textarea {
            padding: 5px;
          }
        }
      }
      .check-container {
        width: 100%;
        .space-between {
          margin-top: 10px;
        }
      }
      .space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-around;
    button {
      width: 30%;
    }
  }
  .notes {
    align-items: center;
    justify-content: center;
    width: 100%;
    .title {
      width: 100%;
      padding: 5px;
      font-size: 22px;
      background: #008eb5;
      color: #fff;
      text-align: center;
    }
    .notes-content {
      width: 375px;
      padding: 0 10px;
      .row1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .input {
          border: 1px solid #bbb;
          border-radius: 4px;
          font-size: 16px;
          padding: 0;
          textarea {
            padding: 5px;
          }
        }
      }
      .notes-submit {
        display: flex;
        margin: 10px auto;
      }
      .notes-table {
        .tc {
          font-size: 12px;
          .input {
            border-radius: 4px;
            border: 1px solid #ddd;
            input { 
              padding: 4px;
              font-size: 12px;
            }
          }
          i {
            font-size: 14px;
          }
        }
      }
    }
  }
}